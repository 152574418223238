import React from 'react';

export const markerImageSrc = "/images/marker.png";

const MapMarkerImage = (props) => {
    console.log('Inside function MapMarkerImage');

    // console.log('props.src : ' + props.src);
    console.log('props.show : ' + props.show);

    let markerImage = props.src || markerImageSrc;
    console.log('markerImage : ' + markerImage);

    let altText = props.alt || "marker";
    console.log('altText : ' + altText);
  
    let showFlag = 1;
    if ( 0 === props.show ) {
      showFlag = 0;
    }
    console.log('showFlag : ' + showFlag);

    if (typeof window !== 'undefined') {
      const baseUrl = window.location.origin;
      console.log('baseUrl : ' + baseUrl);
      if (markerImage.toLowerCase().indexOf(baseUrl) === -1) { // if baseUrl not already added ...
        markerImage = baseUrl + markerImage; // ... add it
        console.log('markerImage : ' + markerImage);
      }
    }

    let _markerImage = null;
    if (showFlag) {
        _markerImage = <img src={markerImage} alt={altText} />
    }
    
    return _markerImage;
}

export default MapMarkerImage;