import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import ReactMarkdown from "react-markdown/with-html"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton } from '../icon';
// import ScrollAnimation from 'react-animate-on-scroll';


const ContactSection = (props)=>{
    const videoid = getVideoId(props.getInTouch.Get_In_Touch_Embed_Video_Link
);

  const [showVideo, setShowVideo] = React.useState(false);
  const [,setPlay] = React.useState(false);
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
}
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
	const [] = useState(false)
	useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

     const img  = document.querySelector('.contact-imgx');
    const text = document.querySelector('.textContact');
		document.addEventListener('scroll', handleScroll, {
		    passive: true
		});

		function handleScroll () {
		
  		 if(isInViewport(img)) {
          
          /** Here one can write animate.css class for animation **/
          img.classList.add('animate__fadeInLeft','animate__slower');
          
        } else {
          img.classList.remove('animate__fadeInLeft','animate__slower');
        
        }

        if(isInViewport(text)) {      
          /** Here one can write animate.css class for animation **/
          text.classList.add('animate__fadeInRight','animate__slower');
          
        } else {
          text.classList.remove('animate__fadeInRight','animate__slower');
        
        }


		}

		return () =>{
			document.addEventListener('scroll', handleScroll);
		}

	},[])

	return (<React.Fragment>
      <section className="section-contact ">
        <div className="contact-img contact-imgx">
        {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}

        <div className="vedio-card playing-video">


          <img src={props.getInTouch.Get_In_Touch_Image.childImageSharp.fluid.src} alt="img" />
          {props.getInTouch.Get_In_Touch_Embed_Video_Link !=null && props.getInTouch.Get_In_Touch_Embed_Video_Link !='' &&
          <button className="btn-play"
            onClick = { () => {setPlay(true);setShowVideo(true)}}
          >
            <VideoPlayButton />
          </button>
          }
        </div>
        {
              showVideo &&
                <YouTube
                video={videoid.id}
                autoplay
                onPlaying={trackerVideo('Get in Touch')}

              />
            }
            {/* </ScrollAnimation> */}

                </div>

        <Container>
          <Row className="justify-content-xl-end">
            <Col className="col-xl-6 textContact ">
            {/* <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} > */}
              <div className="contact-text">                
                <ReactMarkdown source={props.getInTouch.Get_In_Touch_Content} escapeHtml={false}/>
                {pageurl == 'https://www.orlandoreid.co.uk/manchester/'? <a class="btn btn-default" href="/contact/our-offices/manchester-offices/manchester-property-investments">contact us</a> :
                <a class="btn btn-default" href="/contact/our-offices/">contact us</a>}
              </div>
              {/* </ScrollAnimation> */}
            </Col>
          </Row>
        </Container>
      </section>
      
	</React.Fragment>)

}

export default ContactSection;